<template>
  <form role="form" class="aptive__login-text-start">
    <div class="aptive__login-mb-3">
      <div class="aptive__login-group">
        <label for="email"> Correo electrónico</label>

        <input id="email"
               type="email"
               class="aptive__login-input"
               name="email"
               placeholder="Correo electrónico"
               :class="{'aptive__login-error': errors.email }"
               v-model="credentials.email">
      </div>
    </div>

    <div class="aptive__login-mb-3" v-if="options.password.enabled && showPasswordField">
      <div class="aptive__login-group">
        <label for="password"> Contraseña </label>

        <input id="password"
               type="password"
               class="aptive__login-input"
               name="password"
               placeholder="Contraseña"
               :class="{'aptive__login-error': errors.password}"
               v-model="credentials.password">
      </div>
    </div>

    <div class="aptive__login-mb-3">
      <div class="aptive__login-group text-end">
        <a class="pointer" @click.prevent="showPasswordField = !showPasswordField">
          {{ showPasswordField ? 'Iniciar sesión con magic link' : 'Iniciar sesión con contraseña' }}
        </a>
      </div>
    </div>

    <div class="aptive__login-text-center">
      <button class="aptive__login-button"
              :disabled="loading"
              @click.prevent="validate">
        {{ showPasswordField ? 'Iniciar sesión' : 'Enviar' }}
        <div v-if="loading" class="aptive__login-spinner"></div>
      </button>
    </div>
  </form>
</template>

<script>
import Validators from "../../helpers/validators";
export default {
  mixins: [Validators],
  inject: ['$axios'],
  props: {
    options: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    showPasswordField: false,
    loading: false
  }),
  computed: {
    url() {
      return this.showPasswordField ? this.options?.password?.url : this.options?.magicLink?.url
    },
    type() {
      return this.showPasswordField ? 'password' : 'magic link'
    }
  },
  methods: {
    changeFields() {
      this.resetErrors()
      this.showPasswordField = !this.showPasswordField;

      this.$emit('changeMode', this.showPasswordField)
    },
    resetErrors() {
      this.errors = {};
    },
    validate() {
      if (this.type === 'password') this.validateField('password');
      this.validateFormat('email', 'email');

      // Check that doesn't exists errors with email field
      if(Object.entries(this.errors).length === 0 && this.errors.constructor === Object) {
        this.submit();
      }
    },
    async submit() {
      if (this.loading) return;
      this.loading = true;

      let dataToSend = this.type === 'magic link' ? {
        data: { email: this.credentials.email }
      } : {
        administrator: { ...this.credentials }
      }
      if (this.url) {
        this.$axios.post(this.url, dataToSend).then(response => {
          let status = response.data;
          if (status.code >= 200 && status.code < 300) {
            this.$emit('onSubmit', { ...status, type: this.type, credentials: this.credentials })
            this.resetErrors();
          } else {
            this.$emit('onError', status)
          }
        }).catch(e => {
          this.$emit('onError', e)
        }).finally(() => {
          this.loading = false;
        })
      } else {
        console.error('No api configured for ' + this.type + ' request, options prop example: ', {
          magicLink: { enabled: true, url: 'api_url'},
          password: { enabled: true, url: 'api_url' }
        })
      }
    }
  },
  validations() {
    return this.credentialsRules;
  }
}
</script>