import axios from 'axios';
import Swal from 'sweetalert2'

axios.interceptors.response.use((response) => {
    // Handle the response here
    return response
}, (error) => {
    // Handle errors here
    if(error.response.data.data == 'Not authorized'){
        Swal.fire({
            icon: 'error',
            title: 'Tu sesión se ha caducado',
            showConfirmButton: true
        });

        window.location.href = '/login'
    }
    return Promise.reject(error)
})

export const generateAxiosInstance = (
    hostApiKey = 'oO60HTCXroXwpsMSYP0cb0cg2hRYNtmSluF',
    baseUrl = null,
    token = null,
) => {
    if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
    axios.defaults.headers.common['x-api-key'] = hostApiKey;
    axios.defaults.headers.common['Accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    axios.defaults.baseURL = baseUrl;
    return axios;
};