<template>
  <main class="mt-0">
    <main class="mt-0 main-content-bg">
      <section>
        <div class="page-header min-vh-100">
          <div class="main-container">
            <div class="d-flex flex-wrap justify-content-center">
              <div
                  class="" style="min-width: 400px;"
              >

                <Login class="rino-login-dif" :options="{
                    password: { enabled: true, url: '/login' },
                    magicLink: { enabled: true, url: '/request-magic-link' },
                  }"
                           @onSubmit="onSubmit"
                           @onError="onError"/>

              </div>
              <div class="col-md-4 d-md-block d-none">
                <div class="top-0 oblique position-absolute h-100 me-n8">
                  <div class="bg-cover oblique-image position-absolute fixed-top ms-auto h-100 z-index-0 ms-n8"
                       style="background: url('/banner-login.jpg');"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </main>
</template>

<script>
import Login from './Login.vue';

export default {
  components: { Login },
  inject: ['$cookies'],
  methods: {
    onSubmit(response) {
      let title = '¡Enlace Mágico Enviado!';
      let text = 'Hemos enviado un enlace de inicio de sesión a tu bandeja de entrada. Revisa tu correo y sigue las instrucciones para continuar.';
        
      if (response.type === 'password') {
        this.$cookies.set('zensavvy_session_token', response.session_token)
        title = '¡Autenticación correcta!'
        text = 'Estamos iniciando sesión, espera un momento…'

        setTimeout(() => {
          window.location.href = '/app'
        }, 1250)
      }

      this.$swal.fire({
        icon: 'success',
        title: title,
        text: text
      });
    },
    onError(e) {
      this.$swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Credenciales inválidas.'
      })
      console.warn(e)
    }
  }
};
</script>